<template>
  <div class="container-md col-lg-10 col-md-10 pt-2 organisation-list">
    <div class="pb-0 mt-3 mb-3">
      <CRow class="pr-0 pl-0">
        <CCol md="12" sm="12" lg="6">
          <h3 class="text-primary main-title my-1">Email Messages</h3>
        </CCol>
        <CCol md="12" sm="12" lg="6" class="d-none d-lg-block">
          <div class="float-right">
            <CButton
              class="mr-0 nowrap"
              color="primary"
              shape="pill"
              @click="backToHome"
              >Back To Home</CButton
            >
          </div>
        </CCol>
      </CRow>
    </div>
    <div class="mt-5">
      <ul class="nav nav-tabs border-0">
        <li class="nav-item">
          <a
            href="#inbox"
            target="_self"
            class="nav-link"
            :class="{ active: currentTab == 'Inbox' }"
            @click="activeTab('Inbox'), inboxTab(null)"
            >Inbox</a
          >
        </li>
        <li class="nav-item">
          <a
            href="#sent"
            target="_self"
            class="nav-link"
            :class="{ active: currentTab == 'Sent' }"
            @click="activeTab('Sent'), sentTab(null)"
            >Sent</a
          >
        </li>
      </ul>
    </div>
    <div>
      <CRow class="mx-0 border rounded">
        <CCol md="12" sm="12" lg="6">
          <div class="d-flex my-2">
            <img
              src="/img/user_default_pic.png"
              alt="user"
              width="30"
              height="30"
              class="rounded-circle mt-1 mr-2"
            />
            <span class="mx-1 my-1">
              <p class="font-style mb-0">{{ getUserName }}</p>
              <p
                class="user-email mb-0"
                style="font-size: 11px; color: #baa39f"
              >
                {{ getCurrentUserEmail }}
              </p>
            </span>
          </div>
        </CCol>
        <CCol md="12" sm="12" lg="6">
          <ul class="nav nav-bar my-2 py-1" align="right">
            <li
              v-if="getRecruiterEmailMessages.length"
              class="nav-item pt-2 mx-2 font-style"
            >
              <span>
                {{
                  itemFrom +
                  " - " +
                  recruiterItemTo +
                  " " +
                  "of" +
                  " " +
                  getEmailMessagesCount
                }}
              </span>
            </li>
            <li v-else class="nav-item pt-2 mx-2 font-style">
              <span>
                {{ "0" + " - " + "0" + " " + "of" + " " + "0" }}
              </span>
            </li>
            <li class="nav-item mx-2">
              <CButton
                :class="{
                  'arrow rounded-circle': pageCount == 1 ? false : true,
                }"
                :disabled="pageCount == 1 ? true : false"
                @click="previousPage(currentTab)"
              >
                <i class="fas fa-angle-left"></i>
              </CButton>
            </li>
            <li class="nav-item mx-2">
              <CButton
                :class="{
                  'arrow rounded-circle':
                    pageCount == emailMessagePageCount ||
                    emailMessagePageCount == 0
                      ? false
                      : true,
                }"
                :disabled="
                  pageCount == emailMessagePageCount ||
                  emailMessagePageCount == 0
                    ? true
                    : false
                "
                @click="nextPage(currentTab)"
              >
                <i class="fas fa-angle-right"> </i>
              </CButton>
            </li>
          </ul>
        </CCol>
        <hr />
      </CRow>
    </div>
    <div>
      <div class="tab-content">
        <div
          class="tab-pane"
          :class="{ active: currentTab == 'Inbox' }"
          :style="currentTab == 'Inbox' ? '' : 'display:none;'"
        >
          <InboxGrid
            :getBoundOnly="getRecruiterEmailMessages"
            :currentTab="currentTab"
            @emailGridCallBack="inboxSearchTerm"
            @applyFilter="filterInbox"
          />
        </div>
        <div
          class="tab-pane"
          :class="{ active: currentTab == 'Sent' }"
          :style="currentTab == 'Sent' ? '' : 'display:none;'"
        >
          <SentGrid
            :getBoundOnly="getRecruiterEmailMessages"
            :currentTab="currentTab"
            @emailGridCallBack="sentSearchTerm"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import InboxGrid from "@/containers/Communication/EmailGrid";
import SentGrid from "@/containers/Communication/EmailGrid";

export default {
  components: {
    InboxGrid,
    SentGrid,
  },
  data() {
    return {
      currentTab: null,
      pageCount: 1,
      itemsPerPage: 15,
      itemFrom: 1,
      itemTo: 15,
      recruiterItemTo: null,
      filter: {
        read: null,
      },
    };
  },
  watch: {
    getRecruiterEmailMessages() {
      this.recruiterItemTo =
        this.getRecruiterEmailMessages.length <= 15
          ? this.getRecruiterEmailMessages.length + this.itemTo
          : this.getRecruiterEmailMessages.length;
    },
    getUser() {
      this.fetchData();
    },
  },
  computed: {
    ...mapGetters([
      "getUser",
      "getRecruiterEmailMessages",
      "emailBoxBackUrl",
      "getNotificationMessagesCount",
      "noMoreDataFromEmailList",
      "getEmailMessagesCount",
      "userFullName",
    ]),
    ...mapMutations(["getEmailPagination"]),
    getUserEmail() {
      return this.getUser.full_name;
    },
    emailMessagePageCount() {
      let totalCount = Math.ceil(this.getEmailMessagesCount / 15);
      return totalCount;
    },
    getCurrentUserEmail() {
      return this.getUserEmail + "@" + window.location.host;
    },
    getUserName() {
      let firstname = this.getUser.first_name;
      let lastname = this.getUser.surname;

      if (firstname && lastname) {
        firstname = firstname
          .replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          })
          .trim();

        lastname = lastname
          .replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          })
          .trim();

        return firstname + " " + lastname;
      }
      return "";
    },
  },
  methods: {
    ...mapActions([
      "updateReadMessage",
      "fetchRecruiterEmailMessages",
      "fetchEmailMessagesCount",
      "fetchSearchTermEmailMessages",
      "fetchSearchTermEmailMessagesCount",
    ]),
    backToHome() {
      this.$router.push(this.emailBoxBackUrl);
    },
    activeTab(value) {
      this.pageCount = 1;
      this.itemFrom = 1;
      this.itemTo = 0;
      this.$store.state.communication.getEmailPagination.noMoreDataFromEmailList = false;
      this.currentTab = value;
    },
    previousPage(currentTab) {
      this.pageCount -= 1;
      if (this.$store.state.communication.getEmailPagination.skip != 0)
        this.$store.state.communication.getEmailPagination.skip -= 15;
      if (currentTab == "Notification") {
        let direction = "I";
        let read = "false";
        this.fetchRecruiterEmailMessages({
          sender: this.getUserEmail,
          read: read,
          direction: direction,
        });
        this.itemTo -= this.itemsPerPage;
        this.itemFrom -= this.itemsPerPage;
      } else if (currentTab == "Inbox") {
        let direction = "I";
        this.fetchRecruiterEmailMessages({
          sender: this.getUserEmail,
          direction: direction,
          read: this.filter.read,
        });
        this.itemTo -= this.itemsPerPage;
        this.itemFrom -= this.itemsPerPage;
      } else if (currentTab == "Sent") {
        let direction = "O";
        this.fetchRecruiterEmailMessages({
          sender: this.getUserEmail,
          direction: direction,
        });
        this.itemTo -= this.itemsPerPage;
        this.itemFrom -= this.itemsPerPage;
      }
    },
    nextPage(currentTab) {
      this.pageCount += 1;
      this.$store.state.communication.getEmailPagination.skip += 15;
      if (currentTab == "Notification") {
        let read = "false";
        let direction = "I";
        this.fetchRecruiterEmailMessages({
          sender: this.getUserEmail,
          read: read,
          direction: direction,
        });
        this.itemFrom += this.itemsPerPage;
        this.itemTo += this.itemsPerPage;
      } else if (currentTab == "Inbox") {
        let direction = "I";
        this.fetchRecruiterEmailMessages({
          sender: this.getUserEmail,
          direction: direction,
          read: this.filter.read,
        });
        this.itemFrom += this.itemsPerPage;
        this.itemTo += this.itemsPerPage;
      } else if (currentTab == "Sent") {
        let direction = "O";
        this.fetchRecruiterEmailMessages({
          sender: this.getUserEmail,
          direction: direction,
        });
        this.itemFrom += this.itemsPerPage;
        this.itemTo += this.itemsPerPage;
      }
    },
    inboxTab() {
      let direction = "I";
      this.$store.state.communication.getEmailPagination.skip = 0;
      this.fetchRecruiterEmailMessages({
        sender: this.getUserEmail,
        direction: direction,
        read: this.filter.read,
      });
      this.fetchEmailMessagesCount({
        sender: this.getUserEmail,
        direction: direction,
        read: this.filter.read,
      });
    },
    sentTab() {
      let direction = "O";
      this.$store.state.communication.getEmailPagination.skip = 0;
      this.fetchRecruiterEmailMessages({
        sender: this.getUserEmail,
        direction: direction,
      });
      this.fetchEmailMessagesCount({
        sender: this.getUserEmail,
        direction: direction,
      });
    },
    inboxSearchTerm(data) {
      let direction = "I";
      this.$store.state.communication.getEmailPagination.skip = 0;
      this.fetchSearchTermEmailMessagesCount({
        direction: direction,
        searchTerm: data,
        read: this.filter.read,
      });
      this.fetchSearchTermEmailMessages({
        direction: direction,
        searchTerm: data,
        read: this.filter.read,
      });
    },
    sentSearchTerm(data) {
      let direction = "O";
      this.$store.state.communication.getEmailPagination.skip = 0;
      this.fetchSearchTermEmailMessagesCount({
        direction: direction,
        searchTerm: data,
      });
      this.fetchSearchTermEmailMessages({
        direction: direction,
        searchTerm: data,
      });
    },
    fetchData() {
      let direction = "I";
      this.$store.state.communication.getEmailPagination.skip = 0;
      if (this.getUserEmail) {
        this.fetchRecruiterEmailMessages({
          sender: this.getUserEmail,
          direction: direction,
        });
        this.fetchEmailMessagesCount({
          sender: this.getUserEmail,
          direction: direction,
        });
      }
      this.pageCount = 1;
      this.itemFrom = 1;
      this.itemTo = 0;
      this.currentTab = "Inbox";
    },
    filterInbox(filter) {
      this.filter.read = filter;
      this.pageCount = 1;
      this.itemFrom = 1;
      this.itemTo = 0;
      this.$store.state.communication.getEmailPagination.noMoreDataFromEmailList = false;
      this.inboxTab();
    },
  },
  mounted() {
    this.fetchData();
  },
  created() {
    window.addEventListener("resize", () => {
      this.$store.commit("SET_WINDOW_WIDTH");
    });
    this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
.nav-bar {
  // padding: 10px 0px 5px 10px;
  float: right;
  height: 39px;
}
.font-style {
  font-weight: 600;
  color: #4f5d73;
  font-size: 12px;
}
@media (max-width: 520px) {
  .nav-bar {
    float: none;
  }
  .nav-tabs {
    border-bottom: none;
  }
}
.input-group-append {
  height: calc(1.5em + 0.75rem + 5px);
}

li:hover .arrow {
  padding: 0.475rem 0.75rem;
  background-color: #dbdbdb;
  transition: 0.5s ease;
}
</style>
